import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
} from '@remix-run/react';
import toastStyles from 'react-toastify/dist/ReactToastify.css?url';
import { NextUIProvider } from '@nextui-org/react';
import { ToastContainer } from 'react-toastify';
export const links = () => [{ rel: 'stylesheet', href: toastStyles }];

export function meta() {
  return [
    { charset: 'utf-8' },
    { title: 'Lacasatour' },
    { viewport: 'width=device-width,initial-scale=1' },
  ];
}

export function ErrorBoundary() {
  let error = useRouteError();
  captureRemixErrorBoundaryError(error);
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <div
          style={{
            textAlign: 'center',
            padding: '20px',
            backgroundColor: '#f8f9fa',
            borderRadius: '5px',
            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
          }}
        >
          <h1 style={{ color: '#6c757d', marginBottom: '20px' }}>Oops</h1>
          <p style={{ marginBottom: '20px' }}>Something went wrong, Please try this again later.</p>
          <p
            style={{
              backgroundColor: '#dc3545',
              color: '#fff',
              padding: '10px',
              borderRadius: '5px',
              marginBottom: '20px',
            }}
          >
            {error.message}
          </p>
          <pre
            style={{
              backgroundColor: '#e9ecef',
              padding: '10px',
              borderRadius: '5px',
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
          >
            {error.stack}
          </pre>
        </div>
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
}

function App() {
  return (
    <html lang="en" data-theme="light">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <NextUIProvider validationBehavior="native">
          <Outlet />
        </NextUIProvider>
        <ScrollRestoration />
        <Scripts />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick="true"
          pauseOnHover="true"
          draggable="true"
          theme="light"
        />
      </body>
    </html>
  );
}

export default withSentry(App);